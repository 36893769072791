import EventEmitter from "events";
import SSE from "../utils/sse";
import { OpenAIMessage, Parameters } from "./types";
import { backend, User } from "../backend";

export const defaultModel = 'gpt-3.5-turbo';

export function isProxySupported() {
    return !!backend.current?.services?.includes('openai');
}

function shouldUseProxy(apiKey: string | undefined | null) {
    return !apiKey && isProxySupported();
}

function getEndpoint(proxied = false) {
    return proxied ? '/chatapi/proxies/openai' : 'https://api.openai.com';
}

export interface OpenAIResponseChunk {
    id?: string;
    done: boolean;
    choices?: {
        delta: {
            content: string;
        };
        index: number;
        finish_reason: string | null;
    }[];
    model?: string;
}

function parseResponseChunk(buffer: any): OpenAIResponseChunk {
    const chunk = buffer.toString().replace('data: ', '').trim();

    if (chunk === '[DONE]') {
        return {
            done: true,
        };
    }

    const parsed = JSON.parse(chunk);

    return {
        id: parsed.id,
        done: false,
        choices: parsed.choices,
        model: parsed.model,
    };
}

export async function createChatCompletion(messages: OpenAIMessage[], parameters: Parameters): Promise<string> {
    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    if (!proxied && !parameters.apiKey) {
        throw new Error('No API key provided');
    }

    const response = await fetch(endpoint + '/v1/chat/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "model": parameters.model,
            "messages": messages,
            "temperature": parameters.temperature,
        }),
    });

    const data = await response.json();

    return data.choices[0].message?.content?.trim() || '';
}

export async function createStreamingChatCompletion(messages: OpenAIMessage[], parameters: Parameters) {
    const emitter = new EventEmitter();

    const proxied = shouldUseProxy(parameters.apiKey);
    const endpoint = getEndpoint(proxied);

    if (!proxied && !parameters.apiKey) {
        throw new Error('No API key provided');
    }

    const eventSource = new SSE(endpoint + '/v1/chat/completions', {
        method: "POST",
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Authorization': !proxied ? `Bearer ${parameters.apiKey}` : '',
            'Content-Type': 'application/json',
        },
        payload: JSON.stringify({
            "model": parameters.model,
            "messages": messages,
            "temperature": parameters.temperature,
            "stream": true,
        }),
    }) as SSE;

    let contents = '';

    eventSource.addEventListener('error', (event: any) => {
        if (!contents) {
            let error = event.data;
            try {
                error = JSON.parse(error).error.message;
            } catch (e) {}
            emitter.emit('error', error);
        }
    });

    eventSource.addEventListener('message', async (event: any) => {
        if (event.data === '[DONE]') {
            emitter.emit('done');
            return;
        }

        try {
            const chunk = parseResponseChunk(event.data);
            if (chunk.choices && chunk.choices.length > 0) {
                contents += chunk.choices[0]?.delta?.content || '';
                emitter.emit('data', contents);
            }
        } catch (e) {
            console.error(e);
        }
    });

    eventSource.stream();

    return {
        emitter,
        cancel: () => eventSource.close(),
    };
}

const maxTokensByModel = {
    "claude-3-opus-20240229": 200_000,
    "claude-3-sonnet-20240229": 200_000,
    "claude-3-haiku-20240307": 200_000,
    "claude-3-5-sonnet-20240620": 200_000,
    "gpt-4-0125-preview": 128_000,
    "gpt-4-1106-preview": 128_000,
    "babbage-002": 16384,
    "gpt-4-turbo-preview": 128_000,
    "gpt-4o-mini-2024-07-18": 128_000,
    "gpt-4o-2024-08-06": 128_000,
    "gpt-4o-2024-05-13": 128_000,
    "gpt-3.5-turbo-0613": 4096,
    "gpt-4o-mini": 128_000,
    "gpt-4": 8192,
    "gpt-3.5-turbo": 16385,
    "gpt-3.5-turbo-1106": 16385,
    "gpt-3.5-turbo-16k": 16385,
    "gpt-3.5-turbo-16k-0613": 16385,
    "chatgpt-4o-latest": 128_000,
    "gpt-3.5-turbo-instruct-0914": 4096,
    "gpt-3.5-turbo-0125": 16385,
    "gpt-4o": 128_000,
    "gpt-4-0613": 8192,
    "gpt-3.5-turbo-instruct": 4096,
    "gpt-3.5-turbo-0301": 16385,
    "gpt-4-turbo-2024-04-09": 128_000,
    "gpt-4-turbo": 128_000,
    "gemma2-9b-it": 8000,
    "gemma-7b-it": 8192,
    "llama-3.1-70b-versatile": 131_072,
    "llama-3.1-8b-instant": 131_072,
    "llama3-70b-8192": 8192,
    "llama3-8b-8192": 8192,
    "llama3-groq-70b-8192-tool-use-preview": 8192,
    "llama3-groq-8b-8192-tool-use-preview": 8192,
    "llama-guard-3-8b": 8192,
    "mixtral-8x7b-32768": 32768,
    "open-mistral-7b": 32768,
    "mistral-tiny": 32768,
    "mistral-tiny-2312": 32768,
    "open-mistral-nemo": 128_000,
    "open-mistral-nemo-2407": 128_000,
    "mistral-tiny-2407": 32768,
    "mistral-tiny-latest": 32768,
    "open-mixtral-8x7b": 32768,
    "mistral-small": 32768,
    "mistral-small-2312": 32768,
    "open-mixtral-8x22b": 64_000,
    "open-mixtral-8x22b-2404": 64_000,
    "mistral-small-2402": 32768,
    "mistral-small-latest": 32768,
    "mistral-medium-2312": 32768,
    "mistral-medium": 32768,
    "mistral-medium-latest": 32768,
    "mistral-large-2402": 128_000,
    "mistral-large-2407": 128_000,
    "mistral-large-latest": 128_000,
    "codestral": 32768,
    "codestral-latest": 32768,
    "codestral-mamba-2407": 256_000,
    "open-codestral-mamba": 256_000,
    "codestral-mamba-latest": 256_000,
}

const MAX_STANDARD_LIMIT = 32768

export function getLimitForUser(user: User | null, model: string) {
    const limit = maxTokensByModel[model] || 2048

    if (!user) {
        return limit
    }


    return user.hasAccessToAdvancedModels ? limit : Math.min(MAX_STANDARD_LIMIT, limit)
}